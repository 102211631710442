import React from "react";
import Collage from "../components/Collage";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";

const Prodcuts = () => {
  return (
    <Layout>
      <Collage />
    </Layout>
  );
};

export default Prodcuts;
