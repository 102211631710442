import React from "react";
// @ts-ignore
import WeddingImg from "../images/wedding.jpg";
// @ts-ignore
import WeddingGirlImg from "../images/wedding_girl.jpg";
// @ts-ignore
import Photography from "../images/photography.jpg";
// @ts-ignore
import Events from "../images/events.jpg";
// @ts-ignore
import Kid from "../images/kids.jpg";

const Collage = () => {
  const list_images = [...Array(12).keys()].map((i) => `${i + 1}.jpg`);

  return (
    <div className="grid grid-cols-1 gap-1 md:grid-cols-3 mb-10 md:gap-4">
      {list_images.map((img) => {
        return (
          <div className="h-full w-full max-h-[30rem]">
            <img
              className="h-full w-full object-cover"
              src={`/img/${img}`}
              alt="Wedding of Couple"
            />
          </div>
        );
      })}
    </div>
  );
};

export default Collage;
